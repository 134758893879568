import {
    Box,
    Container,
    Flex,
    Stack,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { PupilPassport } from "./components/pupilPassport";
import { PupilStatistics } from "./components/pupilStatistics";
import { RewardRequestTable } from "./components/rewardRequestTable";
import { useGetPupilPassportDataQuery } from "./models/getPupilPassportData";
import { useGetShopItemOrdersQuery } from "./models/getShopItemOrders";
import { useTranslation } from "react-i18next";

const useQuery = () => {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const PupilPage = () => {
    const { t } = useTranslation();
    const query = useQuery();
    const borderColor = useColorModeValue("gray.200", "gray.500");
    const backgroundColor = useColorModeValue("gray.50", "gray.700");

    const pupilId = query.get(t("routes.pupil.parameters.pupilId"));
    const { data: getPupilPassportDataResult, loading: getPupilPassportDataLoading} = useGetPupilPassportDataQuery({ variables: { pupilId: pupilId || "" }, skip: !pupilId});
    const { data: shopItemOrders, loading: shopItemOrdersLoading } = useGetShopItemOrdersQuery({ variables: { pupilId: pupilId || "" }, skip: !pupilId});

    return (
        <Container maxW={"7xl"}>
            <Stack spacing={{ base: 6, md: 10 }}>
                <Flex direction={"row"}>
                    <Box
                        shadow="base"
                        borderWidth="1px"
                        borderColor={borderColor}
                        backgroundColor={backgroundColor}
                        borderRadius={"xl"}
                        padding={"20px"}
                        margin={"10px"}
                        flexBasis={"75%"}
                    >
                        <Flex flexDirection={"column"} height={"100%"} justifyContent={"center"}>
                            <PupilPassport getPupilPassportDataResult={getPupilPassportDataResult} getPupilPassportDataLoading={getPupilPassportDataLoading} />
                        </Flex>
                    </Box>
                    <Flex direction={"column"} flexBasis={"25%"} justifyContent={"space-around"}>
                        <PupilStatistics getPupilPassportDataResult={getPupilPassportDataResult} />
                    </Flex>
                </Flex>

                <Box
                    shadow="base"
                    borderWidth="1px"
                    borderColor={borderColor}
                    backgroundColor={backgroundColor}
                    borderRadius={"xl"}
                    padding={"20px"}
                    margin={"10px"}
                    flexBasis={"100%"}
                >
                    <Flex direction="column" height={"100%"}>
                        <Flex alignItems="center" marginBottom={"10px"}>
                            <Text fontSize="xl" fontWeight="500" marginRight={"10px"}>
                                🏆 {t("pupilPage.shopItemRequests")}
                            </Text>
                        </Flex>
                        {
                            shopItemOrders && shopItemOrders.shopItemOrders.length === 0 && 
                                <Text fontSize="md" fontWeight="500" marginRight={"10px"}> Deze leerling heeft nog geen beloningen aangevraagd </Text>
                        }
                        {
                            (shopItemOrdersLoading || (shopItemOrders && shopItemOrders.shopItemOrders.length > 0)) && 
                                <RewardRequestTable shopItemOrders={shopItemOrders} shopItemOrdersLoading={shopItemOrdersLoading} />
                        }
                    </Flex>
                </Box>
            </Stack>
        </Container>
    );
};
