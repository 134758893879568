/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetShopItemAssignmentStockMutationVariables = Types.Exact<{
  shopItemAssignmentId: Types.Scalars['String']['input'];
  newPhysicalStock: Types.Scalars['Int']['input'];
  newAvailableStock?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type SetShopItemAssignmentStockMutation = { __typename?: 'Mutation', updateOneShopItemAssignment: { __typename?: 'ShopItemAssignment', id: string } };


export const SetShopItemAssignmentStockDocument = gql`
    mutation setShopItemAssignmentStock($shopItemAssignmentId: String!, $newPhysicalStock: Int!, $newAvailableStock: Int) {
  updateOneShopItemAssignment(
    id: $shopItemAssignmentId
    input: {physicalStock: $newPhysicalStock, availableStock: $newAvailableStock}
  ) {
    id
  }
}
    `;
export type SetShopItemAssignmentStockMutationFn = Apollo.MutationFunction<SetShopItemAssignmentStockMutation, SetShopItemAssignmentStockMutationVariables>;

/**
 * __useSetShopItemAssignmentStockMutation__
 *
 * To run a mutation, you first call `useSetShopItemAssignmentStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShopItemAssignmentStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShopItemAssignmentStockMutation, { data, loading, error }] = useSetShopItemAssignmentStockMutation({
 *   variables: {
 *      shopItemAssignmentId: // value for 'shopItemAssignmentId'
 *      newPhysicalStock: // value for 'newPhysicalStock'
 *      newAvailableStock: // value for 'newAvailableStock'
 *   },
 * });
 */
export function useSetShopItemAssignmentStockMutation(baseOptions?: Apollo.MutationHookOptions<SetShopItemAssignmentStockMutation, SetShopItemAssignmentStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetShopItemAssignmentStockMutation, SetShopItemAssignmentStockMutationVariables>(SetShopItemAssignmentStockDocument, options);
      }
export type SetShopItemAssignmentStockMutationHookResult = ReturnType<typeof useSetShopItemAssignmentStockMutation>;
export type SetShopItemAssignmentStockMutationResult = Apollo.MutationResult<SetShopItemAssignmentStockMutation>;
export type SetShopItemAssignmentStockMutationOptions = Apollo.BaseMutationOptions<SetShopItemAssignmentStockMutation, SetShopItemAssignmentStockMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    