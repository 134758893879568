/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShopItemAssignmentStockQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['String']['input'];
}>;


export type GetShopItemAssignmentStockQuery = { __typename?: 'Query', shopItemAssignments: Array<{ __typename?: 'ShopItemAssignment', id: string, physicalStock?: number | null, openRequestCount: number }> };


export const GetShopItemAssignmentStockDocument = gql`
    query getShopItemAssignmentStock($assignmentId: String!) {
  shopItemAssignments(
    filter: {id: {equals: $assignmentId}}
    paging: {limit: 1, offset: 0}
  ) {
    id
    physicalStock
    openRequestCount
  }
}
    `;

/**
 * __useGetShopItemAssignmentStockQuery__
 *
 * To run a query within a React component, call `useGetShopItemAssignmentStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemAssignmentStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemAssignmentStockQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetShopItemAssignmentStockQuery(baseOptions: Apollo.QueryHookOptions<GetShopItemAssignmentStockQuery, GetShopItemAssignmentStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShopItemAssignmentStockQuery, GetShopItemAssignmentStockQueryVariables>(GetShopItemAssignmentStockDocument, options);
      }
export function useGetShopItemAssignmentStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopItemAssignmentStockQuery, GetShopItemAssignmentStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShopItemAssignmentStockQuery, GetShopItemAssignmentStockQueryVariables>(GetShopItemAssignmentStockDocument, options);
        }
export type GetShopItemAssignmentStockQueryHookResult = ReturnType<typeof useGetShopItemAssignmentStockQuery>;
export type GetShopItemAssignmentStockLazyQueryHookResult = ReturnType<typeof useGetShopItemAssignmentStockLazyQuery>;
export type GetShopItemAssignmentStockQueryResult = Apollo.QueryResult<GetShopItemAssignmentStockQuery, GetShopItemAssignmentStockQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    