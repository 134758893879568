import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ResponsiveValue, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";

interface Props {
    isOpened: boolean;
    title: string;
    size?: ResponsiveValue<"full" | "sm" | "md" | "lg" | "xl" | "2xl" | (string & {}) | "xs" | "3xl" | "4xl" | "5xl" | "6xl"> | undefined;
    onConfirm?: () => void;
    onDeny?: () => void;
    onClose?: () => void;
    children: React.ReactNode;
}

export const ConfirmDenyModal = (props: Props) => {
    const { isOpened, title, onConfirm, onDeny, onClose: onCloseModal, children, size } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (isOpened) {
            onOpen();
        } else {
            onClose();
        }
    }, [isOpened, onOpen, onClose]);

    return (
        <Modal isCentered isOpen={isOpen} onClose={onDeny || onCloseModal || (() => {})} size={size}>
            <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px)"
            />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {children}
          </ModalBody>
          <ModalFooter>
            <Button
                _hover={{ backgroundColor: "green", color: "white"}}
                margin="10px"
                onClick={onConfirm}
                hidden={!onConfirm}
            >
                <CheckIcon />
            </Button>
            <Button
                _hover={{ backgroundColor: "red", color: "white" }}
                margin="10px"
                onClick={onDeny}
                hidden={!onDeny}
            >
                <CloseIcon />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
};