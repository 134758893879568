import { Search2Icon } from "@chakra-ui/icons";
import { Box, Button, Flex, HStack, Heading, PinInput, PinInputField, Spinner, Stack, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetPupilByTagIdLazyQuery } from "./models/searchPupilByTagId";
import { useTranslation } from "react-i18next";


export const TagInputPage = () => {
    const navigate = useNavigate();
    const [ searchPupil ] = useGetPupilByTagIdLazyQuery();
    const { t } = useTranslation();

    const [ pupilTagId, setPupilTagId ] = useState<string>();
    const [ isWalkTagInvalid, setIsWalkTagInvalid ] = useState<boolean>(false);
    const [ isBikeTagInvalid, setIsBikeTagInvalid ] = useState<boolean>(false);

    const [ isWalkTagLoading, setIsWalkTagLoading ] = useState<boolean>(false);
    const [ isBikeTagLoading, setIsBikeTagLoading ] = useState<boolean>(false);

    const triggerSearchPupil = async () => {
        if (pupilTagId) {
            const pupilSearchResult = await searchPupil({ variables: { pupilTags: [pupilTagId], now: new Date().toISOString() } });

            if (pupilSearchResult && pupilSearchResult.data && pupilSearchResult.data.pupils && pupilSearchResult.data.pupils.length > 0) {
                const pupil = pupilSearchResult.data.pupils[0];
                const pupilId = pupil.id;
                navigate(`${t("routes.pupil.route")}?${t("routes.pupil.parameters.pupilId")}=${pupilId}`);
            }
        }
    };


    const onPupilTagIdChange = (tagId: string) => {
        const fixedTagID = tagId.toUpperCase().replace(/O/g, "0")
        setPupilTagId(fixedTagID);
        setIsWalkTagInvalid(false);
        setIsBikeTagInvalid(false);
    };


    const onWalkTagSearch = async () => {
        setIsWalkTagLoading(true);
        await triggerSearchPupil();
        setIsWalkTagInvalid(true);
        setIsWalkTagLoading(false);
    };

    const onBikeTagSearch = async () => {
        setIsBikeTagLoading(true);
        await triggerSearchPupil();
        setIsBikeTagInvalid(true);
        setIsBikeTagLoading(false);
    };

    return (
        <Flex minHeight="80vh" justifyContent="center" alignItems="center" direction="column">
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="4xl">
                    { t("tagInputPage.title") }
                </Heading>
                <Text fontSize="lg" color="gray.500">
                    { t("tagInputPage.subTitle") }
                </Text>
            </VStack>
            <Stack
                direction={{ base: "column", md: "row" }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={10}
            >
                <VStack>
                    <Box
                        shadow="base"
                        borderWidth="1px"
                        borderColor={useColorModeValue("gray.200", "gray.500")}
                        backgroundColor={useColorModeValue("gray.50", "gray.700")}
                        borderRadius="xl"
                        padding="20px"
                        margin="10px"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize="medium"
                            marginBottom="20px"
                        >
                            🚶 { t("tagInputPage.walkTag") } 
                        </Text>
                        <HStack>
                            <PinInput type="alphanumeric" onChange={(e) => (onPupilTagIdChange(e))} isInvalid={isWalkTagInvalid}>
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                            </PinInput>
                            <Button colorScheme="blue" onClick={onWalkTagSearch}>
                                { isWalkTagLoading ? <Spinner size="sm" /> : <Search2Icon /> }
                            </Button>
                        </HStack>
                        {
                            isWalkTagInvalid && (
                                <Text color="red.500">
                                    { t("tagInputPage.input.error") }
                                </Text>
                            )
                        }
                    </Box>

                    <Text 
                        marginTop="50px" 
                        marginBottom="50px"
                        fontSize="3xl"
                    >
                        { t("tagInputPage.or") }
                    </Text>
                
                    <Box
                        shadow="base"
                        borderWidth="1px"
                        borderColor={useColorModeValue("gray.200", "gray.500")}
                        backgroundColor={useColorModeValue("gray.50", "gray.700")}
                        borderRadius="xl"
                        padding="20px"
                        margin="10px"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize="medium"
                            marginBottom="20px"
                        >
                            🚲 { t("tagInputPage.bikeTag") } 
                        </Text>
                        <HStack>
                            <PinInput type="alphanumeric" defaultValue="MD2023000" onChange={(e) => (onPupilTagIdChange(e))} isInvalid={isBikeTagInvalid}>
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                                <PinInputField fontFamily="monospace" />
                            </PinInput>
                            <Button colorScheme="blue" onClick={onBikeTagSearch}>
                                { isBikeTagLoading ? <Spinner size="sm" /> : <Search2Icon /> }
                            </Button>
                        </HStack>
                        {
                            isBikeTagInvalid && (
                                <Text color="red.500">
                                    { t("tagInputPage.input.error") }
                                </Text>
                            )
                        }
                    </Box>
                </VStack>
            </Stack>
        </Flex>
    );
}