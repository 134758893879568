/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPupilByTagIdQueryVariables = Types.Exact<{
  pupilTags?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  now: Types.Scalars['DateTime']['input'];
}>;


export type GetPupilByTagIdQuery = { __typename?: 'Query', pupils: Array<{ __typename?: 'Pupil', id: string }> };


export const GetPupilByTagIdDocument = gql`
    query getPupilByTagId($pupilTags: [String!], $now: DateTime!) {
  pupils(
    filter: {AND: [{class: {school: {schoolyear: {startsAt: {lte: $now}, endsAt: {gte: $now}}}}}, {OR: [{pupilTag: {type: "BIKE", tag: {containsSome: $pupilTags}}}, {pupilTag: {type: "HIGH_FIVE", tag: {containsSome: $pupilTags}}}]}]}
  ) {
    id
  }
}
    `;

/**
 * __useGetPupilByTagIdQuery__
 *
 * To run a query within a React component, call `useGetPupilByTagIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPupilByTagIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPupilByTagIdQuery({
 *   variables: {
 *      pupilTags: // value for 'pupilTags'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useGetPupilByTagIdQuery(baseOptions: Apollo.QueryHookOptions<GetPupilByTagIdQuery, GetPupilByTagIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPupilByTagIdQuery, GetPupilByTagIdQueryVariables>(GetPupilByTagIdDocument, options);
      }
export function useGetPupilByTagIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPupilByTagIdQuery, GetPupilByTagIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPupilByTagIdQuery, GetPupilByTagIdQueryVariables>(GetPupilByTagIdDocument, options);
        }
export type GetPupilByTagIdQueryHookResult = ReturnType<typeof useGetPupilByTagIdQuery>;
export type GetPupilByTagIdLazyQueryHookResult = ReturnType<typeof useGetPupilByTagIdLazyQuery>;
export type GetPupilByTagIdQueryResult = Apollo.QueryResult<GetPupilByTagIdQuery, GetPupilByTagIdQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    