/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelShopItemOrderForStockChangeMutationVariables = Types.Exact<{
  orderId: Types.Scalars['String']['input'];
  reason?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CancelShopItemOrderForStockChangeMutation = { __typename?: 'Mutation', cancelOneShopItemOrder: { __typename?: 'ShopItemOrder', shopItem?: { __typename?: 'AssignedShopItem', id: string } | null } };


export const CancelShopItemOrderForStockChangeDocument = gql`
    mutation cancelShopItemOrderForStockChange($orderId: String!, $reason: String) {
  cancelOneShopItemOrder(
    orderId: $orderId
    reason: $reason
    dontUpdateAvailableStock: true
  ) {
    shopItem {
      id
    }
  }
}
    `;
export type CancelShopItemOrderForStockChangeMutationFn = Apollo.MutationFunction<CancelShopItemOrderForStockChangeMutation, CancelShopItemOrderForStockChangeMutationVariables>;

/**
 * __useCancelShopItemOrderForStockChangeMutation__
 *
 * To run a mutation, you first call `useCancelShopItemOrderForStockChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelShopItemOrderForStockChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelShopItemOrderForStockChangeMutation, { data, loading, error }] = useCancelShopItemOrderForStockChangeMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelShopItemOrderForStockChangeMutation(baseOptions?: Apollo.MutationHookOptions<CancelShopItemOrderForStockChangeMutation, CancelShopItemOrderForStockChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelShopItemOrderForStockChangeMutation, CancelShopItemOrderForStockChangeMutationVariables>(CancelShopItemOrderForStockChangeDocument, options);
      }
export type CancelShopItemOrderForStockChangeMutationHookResult = ReturnType<typeof useCancelShopItemOrderForStockChangeMutation>;
export type CancelShopItemOrderForStockChangeMutationResult = Apollo.MutationResult<CancelShopItemOrderForStockChangeMutation>;
export type CancelShopItemOrderForStockChangeMutationOptions = Apollo.BaseMutationOptions<CancelShopItemOrderForStockChangeMutation, CancelShopItemOrderForStockChangeMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    