import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { User } from "oidc-client-ts";
import { oidcConfig } from "oidcConfig";

const cache = new InMemoryCache();

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_API_URL || window?.appEnvironment?.API_URL,
	fetch: (...args) => fetch(...args),
});

const authLink = setContext((_, { headers }) => {
	const oidcStorage = localStorage.getItem(`oidc.user:${(oidcConfig as any).authority}:${(oidcConfig as any).client_id}`)
	if (!oidcStorage) {
		return {
			headers: {
				...headers
			},
		};
	}

	const user = User.fromStorageString(oidcStorage);

	return {
		headers: {
			...headers,
			"Authorization": `Bearer ${user?.id_token}`
		},
	};
});

export const client = new ApolloClient({
	cache,
	link: authLink.concat(httpLink),
	name: "high-five-reward-manager-assistant-app",
	version: "1.0.0",
});

// All 3 properties must be set in order for the defaultOptions to work.
// https://github.com/apollographql/apollo-client/issues/2555#issuecomment-449871538
client.defaultOptions = {
	watchQuery: {
		fetchPolicy: process.env.REACT_APP_ENVIRONMENT === "local" ? "network-only" : "cache-and-network",
		errorPolicy: "all",
	},
	query: {
		fetchPolicy: process.env.REACT_APP_ENVIRONMENT === "local" ? "network-only" : "cache-first",
		errorPolicy: "all",
	},
	mutate: {
		errorPolicy: "all",
	},
};
