import { CloseIcon } from "@chakra-ui/icons";
import { Button, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { ConfirmDenyModal } from "components/modal/confirmDenyModal";
import { Trans, useTranslation } from "react-i18next";
import { useCancelShopItemOrderForStockChangeMutation } from "../models/cancelShopItemOrderForStockChange";
import { useGetOpenShopItemOrdersForAssignedShopItemQuery } from "../models/getOpenShopItemOrdersForAssignedShopItem";

interface Props {
    assignedShopItemId: string;
    targetAmount: number;
    isOpened: boolean;
    onClose: () => void;
}

export const CancelOrdersModal = (props: Props) => {
    const { assignedShopItemId, isOpened, targetAmount, onClose } = props;

    const { t } = useTranslation();
    const textColor = useColorModeValue("gray.700", "white");

    const { data } = useGetOpenShopItemOrdersForAssignedShopItemQuery({ variables: { assignedShopItemId }, skip: !isOpened });
    const [ cancelShopItemOrder ] = useCancelShopItemOrderForStockChangeMutation({ refetchQueries: ["getOpenShopItemOrdersForAssignedShopItem", "getShopItemAssignments"] });

    const onCancelOrderClick = async (orderId: string) => {
        await cancelShopItemOrder({ variables: { orderId, reason: t("manageStockPage.confirmDenyModal.defaultCancelReason") } });
    };

    if (data && data.shopItemOrders && data.shopItemOrders.length <= targetAmount) {
        onClose();
    }

    return (
        <ConfirmDenyModal title={t("manageStockPage.confirmDenyModal.title")} isOpened={isOpened} size="4xl" onClose={onClose}>
            <Text>
                <Trans i18nKey="manageStockPage.confirmDenyModal.instructions" components={{ br: <br /> }} values={{ targetAmount: data && data.shopItemOrders && data.shopItemOrders.length - targetAmount }} />
            </Text>
            <Table variant="simple" color={textColor}>
                <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                    {[
                        t("manageStockPage.confirmDenyModal.table.username"), 
                        t("manageStockPage.confirmDenyModal.table.tagIds"),
                        t("manageStockPage.confirmDenyModal.table.requestedAt"),  
                        ""
                    ].map((caption, idx) => {
                        return (
                            <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                                {caption}
                            </Th>
                        );
                    })}
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        ((data && data.shopItemOrders) || []).map((order) => {
                            return (
                                <Tr key={order.id} my=".8rem" pl="0px">
                                    <Td>{order?.pupil?.username?.username || ""}</Td>
                                    <Td>{(order?.pupil?.pupilTags || []).filter((pupilTag) => (pupilTag?.type === "HIGH_FIVE")).map((pupilTag) => (pupilTag?.tag)).slice(0, 3).join(", ")}</Td>
                                    <Td>{order.createdAt && (`${(new Date(order.createdAt)).toLocaleDateString("en-GB")} ${new Date(order.createdAt).toLocaleTimeString("en-GB")}`)}</Td>
                                    <Td>
                                    <Button
                                        _hover={{ backgroundColor: "red", color: "white" }}
                                        onClick={() => (onCancelOrderClick(order.id))}
                                    >
                                        <CloseIcon />
                                    </Button>
                                    </Td>
                                </Tr>
                            );
                        })
                    }
                </Tbody>
            </Table>
        </ConfirmDenyModal>
    );
};