import { useTranslation } from "react-i18next";
import { ApplicationRoutes } from "routes";

export const routeDefinitions: ApplicationRoutes = {
    index: "/",
    tagInput: "/tag-input",
    pupil: "/pupil",
    manageStock: "/manage-stock"
};

export const useRouteDefinitionGenerator = () => {
    const { t } = useTranslation();

    return {
        getRoutes: () => ({
            index: t("routes.index.route"),
            tagInput: t("routes.tagInput.route"),
            pupil: t("routes.pupil.route"),
            manageStock: t("routes.manageStock.route")
        })
    };
};