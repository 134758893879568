import { Statistic } from "components/statistics/statistic";
import { useTranslation } from "react-i18next";
import { GetPupilPassportDataQuery } from "../models/getPupilPassportData";

interface Props {
    getPupilPassportDataResult: GetPupilPassportDataQuery | undefined;
}

export const PupilStatistics = (props: Props) => {
    const { getPupilPassportDataResult } = props;

    const { t } = useTranslation();

    const result = getPupilPassportDataResult || { pupils: [] };
    const pupils = result.pupils || [];
    const pupil = pupils.length ? result.pupils[0] : null;

    return (
        <>
            <Statistic
                title={t("pupilPage.pupilStatistics.highFives")}
                amount={pupil?.highfiveRegistrationsCount}
                icon={"🖐"}
                css={{ flex: "0 0 calc(25% - 20px)" }}
            />
            <Statistic
                title={t("pupilPage.pupilStatistics.bikeRegistrations")}
                amount={pupil?.bikeRegistrationsCount}
                icon={"🚲"}
                css={{ flex: "0 0 calc(25% - 20px)" }}
            />
            <Statistic
                title={t("pupilPage.pupilStatistics.coins")}
                amount={pupil && pupil.pupilTotal && pupil.pupilTotal.totalCredits ? pupil.pupilTotal.totalCredits : undefined}
                icon={"🪙"}
                css={{ flex: "0 0 calc(25% - 20px)" }}
            />
        </>
    );
};