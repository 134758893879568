/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShopItemAssignmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetShopItemAssignmentsQuery = { __typename?: 'Query', shopItemAssignments: Array<{ __typename?: 'ShopItemAssignment', id: string, title?: string | null, physicalStock?: number | null, availableStock?: number | null, deliveredStock: number, openRequestCount: number, createdAt: any, project: { __typename?: 'Project', id: string, title: string }, school?: { __typename?: 'School', id: string, title: string } | null, class?: { __typename?: 'Class', id: string, title: string } | null, shopItem: { __typename?: 'ShopItem', id: string, title?: string | null, physicalStock?: number | null, availableStock: number, deliveredStock: number, openRequestCount: number, image: { __typename?: 'Asset', url: string }, type: { __typename?: 'ShopItemType', type: Types.ShopItemTypeType } } }> };


export const GetShopItemAssignmentsDocument = gql`
    query getShopItemAssignments {
  shopItemAssignments {
    id
    title
    project {
      id
      title
    }
    school {
      id
      title
    }
    class {
      id
      title
    }
    physicalStock
    availableStock
    deliveredStock
    openRequestCount
    shopItem {
      id
      title
      image {
        url
      }
      physicalStock
      availableStock
      deliveredStock
      openRequestCount
      type {
        type
      }
    }
    createdAt
  }
}
    `;

/**
 * __useGetShopItemAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetShopItemAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShopItemAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetShopItemAssignmentsQuery, GetShopItemAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShopItemAssignmentsQuery, GetShopItemAssignmentsQueryVariables>(GetShopItemAssignmentsDocument, options);
      }
export function useGetShopItemAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopItemAssignmentsQuery, GetShopItemAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShopItemAssignmentsQuery, GetShopItemAssignmentsQueryVariables>(GetShopItemAssignmentsDocument, options);
        }
export type GetShopItemAssignmentsQueryHookResult = ReturnType<typeof useGetShopItemAssignmentsQuery>;
export type GetShopItemAssignmentsLazyQueryHookResult = ReturnType<typeof useGetShopItemAssignmentsLazyQuery>;
export type GetShopItemAssignmentsQueryResult = Apollo.QueryResult<GetShopItemAssignmentsQuery, GetShopItemAssignmentsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    