import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
    Avatar,
    Button,
    Flex,
    HStack,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Spinner,
    Td,
    Text,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetShopItemAssignmentStockLazyQuery } from "../models/getShopItemAssignement";
import { useSetShopItemAssignmentStockMutation } from "../models/setShopItemAssigmentStock";
import { CancelOrdersModal } from "./cancelOrdersModal";
import { ShopItemOrderHistoryModal } from "./shopItemOrderHistoryModal";

export enum Status {
    OPEN = "OPEN",
    DELIVERED = "DELIVERED"
}

interface Props {
    id: string;
    logo: string;
    name: string;
    type: string;
    physicalStock: number;
    availableStock: number;
    deliveredRequests: number;
    openRequestCount: number;
}

export const RewardsStockTableRow = (props: Props) => {
    const { id, logo, name, type, physicalStock, deliveredRequests, openRequestCount: initialOpenRequestCount } = props;

    const textColor = useColorModeValue("gray.700", "white");

    const [ isInEditMode, setIsInEditMode ] = useState(false);
    const [ beforeEditPhysicalStockValue, setBeforeEditPhysicalStockValue ] = useState(physicalStock);
    const [ physicalStockValue, setPhysicalStockValue ] = useState(physicalStock);
    const [ openRequestCount, setOpenRequestCount ] = useState(initialOpenRequestCount);

    const [ isCancelOrderModalOpen, setIsCancelOrderModalOpen ] = useState(false);
    const [ isOrderHistoryModalOpen, setIsOrderHistoryModalOpen ] = useState(false);

    const [ loading, setLoading ] = useState(false);
    const [ editModeLoading, setEditModeLoading ] = useState(false);

    const [ setShopItemAssignmentStock ] = useSetShopItemAssignmentStockMutation({ refetchQueries: ["getShopItemAssignments"] });

    const [ getShopItemAssignmentStock ] = useGetShopItemAssignmentStockLazyQuery();

    useEffect(() => {
        if (isInEditMode) {
            setBeforeEditPhysicalStockValue(physicalStockValue);
        }
    }, [isInEditMode]);

    const updateOpenRequestCount = async () => {
        const { data } = await getShopItemAssignmentStock({ variables: { assignmentId: id } });

        if (data && data.shopItemAssignments && data.shopItemAssignments[0]) {
            const shopItemAssignment = data.shopItemAssignments[0];
            setOpenRequestCount(shopItemAssignment.openRequestCount);

            return shopItemAssignment.openRequestCount;
        }

        return null;
    };

    const onStockChangeClicked = async () => {
        setEditModeLoading(true);
        await updateOpenRequestCount();
        setEditModeLoading(false);
        setIsInEditMode(true);
    };

    const onStockChangeConfirmed = async () => {
        const currentOpenRequestCount = await updateOpenRequestCount();

        if (currentOpenRequestCount && physicalStockValue < currentOpenRequestCount) {
            setIsCancelOrderModalOpen(true);
            return;
        }

        setLoading(true);
        setIsInEditMode(false);
        await setShopItemAssignmentStock({ variables: { shopItemAssignmentId: id, newPhysicalStock: physicalStockValue, newAvailableStock: physicalStockValue <= 0 ? 0 : null} });
        setPhysicalStockValue(physicalStockValue);
        setLoading(false);
    };

    const onStockChangeCancelled = () => {
        setIsInEditMode(false);
        setPhysicalStockValue(beforeEditPhysicalStockValue);
    };

    const onCancelOrderModalClose = async () => {
        updateOpenRequestCount();
        setIsCancelOrderModalOpen(false);
    };

    const onShopItemAssignmentClick = () => {
        setIsOrderHistoryModalOpen(true);
    };

    const onOrderHistoryModalClose = () => {
        setIsOrderHistoryModalOpen(false);
    };

    return (
        <Tr>
            {
                type !== "VOUCHER" && isInEditMode && <CancelOrdersModal assignedShopItemId={id} isOpened={isCancelOrderModalOpen} onClose={onCancelOrderModalClose} targetAmount={physicalStockValue} />
            }
            {
                <ShopItemOrderHistoryModal isOpened={isOrderHistoryModalOpen} onClose={onOrderHistoryModalClose} assignedShopItemId={id} assignedShopItemTitle={name} />
            }
            <Td minWidth={{ sm: "250px" }} pl="0px" onClick={onShopItemAssignmentClick}>
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <Avatar src={logo} w="50px" borderRadius="12px" me="18px" />
                <Flex direction="column">
                    <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        minWidth="100%"
                    >
                    {name}
                    </Text>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                        {type.toLowerCase()}
                    </Text>
                </Flex>
                </Flex>
            </Td>
            <Td>
                {
                    isInEditMode ? (
                        <HStack>
                            <NumberInput defaultValue={physicalStock} min={0} value={physicalStockValue} onChange={(e) => (setPhysicalStockValue(parseInt(e, 10)))} color={textColor}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                            <Button
                                _hover={{ backgroundColor: "green", color: "white"}}
                                onClick={onStockChangeConfirmed}
                            >
                                <CheckIcon />
                            </Button>
                            <Button
                                _hover={{ backgroundColor: "red", color: "white" }}
                                onClick={onStockChangeCancelled}
                            >
                                <CloseIcon />
                            </Button>
                        </HStack>
                    ) : (
                        loading ? (
                            <Spinner size="lg" />
                        ) : (
                            <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                                {type === "VOUCHER" ? <CloseIcon /> : physicalStockValue}
                            </Text>
                        )
                    )
                }

            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                    {type === "VOUCHER" ? <CloseIcon /> : openRequestCount}
                </Text>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                    {deliveredRequests}
                </Text>
            </Td>
            <Td>
                <Flex justifyContent={"space-evenly"}>
                    {
                        type !== "VOUCHER" && !isInEditMode && (
                            <Button onClick={() => (onStockChangeClicked())}>
                                { editModeLoading ? <Spinner size="sm" /> : <EditIcon /> }
                            </Button>
                        )
                    }
                </Flex>
            </Td>
        </Tr>
    );
}
  