import {
    Skeleton,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import { Status, TableRow } from "pages/pupil/components/tableRow";
import { useTranslation } from "react-i18next";
import { GetShopItemOrdersQuery } from "../models/getShopItemOrders";

interface Props {
    shopItemOrders: GetShopItemOrdersQuery | undefined;
    shopItemOrdersLoading: boolean;
}

export const RewardRequestTable = (props: Props) => {
    const { shopItemOrders, shopItemOrdersLoading } = props;

    const { t } = useTranslation();
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Table variant="simple" color={textColor}>
            <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                {[
                    t("pupilPage.rewardRequestTable.shopItem"), 
                    t("pupilPage.rewardRequestTable.status"), 
                    t("pupilPage.rewardRequestTable.requestedAt"), 
                    t("pupilPage.rewardRequestTable.deliveredAt"), 
                    t("pupilPage.rewardRequestTable.canceledAt"), 
                    ""
                ].map((caption, idx) => {
                    return (
                        <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                            {caption}
                        </Th>
                    );
                })}
                </Tr>
            </Thead>
            <Tbody>
                {
                    shopItemOrdersLoading &&
                        [1, 2, 3, 4, 5].map((entry) => (
                            <Tr key={entry}>
                                <Td>
                                    <Skeleton width="100%" height="20px" />
                                </Td>
                                <Td>
                                    <Skeleton width="100%" height="20px" />
                                </Td>
                                <Td>
                                    <Skeleton width="100%" height="20px" />
                                </Td>
                                <Td>
                                    <Skeleton width="100%" height="20px" />
                                </Td>
                                <Td>
                                    <Skeleton width="100%" height="20px" />
                                </Td>
                            </Tr>
                        ))
                }
                {
                    (!shopItemOrdersLoading && shopItemOrders && shopItemOrders.shopItemOrders ? shopItemOrders.shopItemOrders : [])
                        .map((row) => {
                            return (
                                <TableRow
                                    key={row.id}
                                    id={row.id}
                                    name={row.shopItem?.title}
                                    type={row.shopItem?.type.type}
                                    logo={row.shopItem?.image.url}
                                    status={row.canceledAt ? Status.CANCELED : (row.deliveredAt ? Status.DELIVERED : Status.OPEN)}
                                    createdAt={new Date(row.createdAt)}
                                    canceledAt={row.canceledAt ? new Date(row.canceledAt) : undefined}
                                    deliveredAt={row.deliveredAt ? new Date(row.deliveredAt) : undefined}
                                />
                            );
                    })
                }
            </Tbody>
        </Table>
    );
};