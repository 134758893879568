import { CheckIcon, CloseIcon, QuestionIcon } from "@chakra-ui/icons";
import {
    Avatar,
    Badge,
    Button,
    Flex,
    Td,
    Text,
    Textarea,
    Tooltip,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import { ConfirmDenyModal } from "components/modal/confirmDenyModal";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useCancelShopItemOrderMutation } from "../models/cancelShopItemOrder";
import { useCompleteShopItemOrderMutation } from "../models/completeShopItemOrder";

export enum Status {
    OPEN = "OPEN",
    CANCELED = "CANCELED",
    DELIVERED = "DELIVERED"
}

interface Props {
    id: string;
    logo: string | undefined | null;
    name: string | undefined | null;
    type: string | undefined | null;
    status: Status;
    createdAt: Date;
    deliveredAt?: Date;
    canceledAt?: Date;
}

export const TableRow = (props: Props) => {
    const { id, logo, name, type, status, createdAt, deliveredAt, canceledAt } = props;

    const { t } = useTranslation();
    const [ isCompleteModalOpen, setIsCompleteModalOpen ] = useState(false);
    const [ isCancelModalOpen, setIsCancelModalOpen ] = useState(false);
    const [ cancelReason, setCancelReason ] = useState("");

    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    const [ completeShopItemOrder ] = useCompleteShopItemOrderMutation({ refetchQueries: ["getShopItemOrders"] });
    const [ cancelShopItemOrder ] = useCancelShopItemOrderMutation({ refetchQueries: ["getShopItemOrders", "getPupilPassportData"] });

    const onCompleteShopItemOrder = (orderId: string) => {
        setIsCompleteModalOpen(true);
    };

    const onCancelShopItemOrder = (orderId: string) => {
        setIsCancelModalOpen(true);
    };

    const onCompleteModalConfirm = async () => {
        setIsCompleteModalOpen(false);
        completeShopItemOrder({ variables: { orderId: id }});
    };

    const onCompleteModalDeny = async () => {
        setIsCompleteModalOpen(false);
    };

    const onCancelModalConfirm = async () => {
        setIsCancelModalOpen(false);
        cancelShopItemOrder({ variables: { orderId: id, reason: cancelReason }});
        setCancelReason("");
    };

    const onCancelModalDeny = async () => {
        setIsCancelModalOpen(false);
    };

    
    const shouldHideCancelButton = (status === Status.CANCELED || type === "VOUCHER");
    const shouldHideCompleteButton = (status === Status.DELIVERED || status === Status.CANCELED);

    return (
        <>
            {
                !shouldHideCompleteButton && (
                    <ConfirmDenyModal title="Weet je het zeker?" isOpened={isCompleteModalOpen} onConfirm={onCompleteModalConfirm} onDeny={onCompleteModalDeny}>
                        <Text>{t("pupilPage.confirmDenyModal.confirm.body")}</Text>
                    </ConfirmDenyModal>
                )
            }
            {
                !shouldHideCancelButton && (
                    <ConfirmDenyModal title={t("pupilPage.confirmDenyModal.deny.title")} isOpened={isCancelModalOpen} onConfirm={onCancelModalConfirm} onDeny={onCancelModalDeny}>
                        <Text>
                            <Trans i18nKey="pupilPage.confirmDenyModal.deny.body" components={{ br: <br /> }} />
                        </Text>
                        <Textarea placeholder={t("pupilPage.confirmDenyModal.denyReasonPlaceholder")} onChange={(e) => (setCancelReason(e.target.value))}/>
                    </ConfirmDenyModal>
                )
            }
            <Tr>
                <Td minWidth={{ sm: "250px" }} pl="0px">
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Avatar src={logo || ""} w="50px" borderRadius="12px" me="18px" />
                    <Flex direction="column">
                        <Text
                            fontSize="md"
                            color={textColor}
                            fontWeight="bold"
                            minWidth="100%"
                        >
                        {name}
                        </Text>
                        <Text fontSize="sm" color="gray.400" fontWeight="normal" display="flex" alignItems="center">
                            {t(`pupilPage.rewardRequestTable.rewardTypes.${type}`)}
                            <Tooltip label={t(`pupilPage.rewardRequestTable.rewardTypeExplaination.${type}`)} fontSize="md">
                                <QuestionIcon marginLeft="5px" />
                            </Tooltip>
                        </Text>
                    </Flex>
                    </Flex>
                </Td>
                <Td>
                    <Badge
                        bg={status === Status.OPEN ? "green.400" : bgStatus}
                        color={status === Status.OPEN ? "white" : colorStatus}
                        fontSize="16px"
                        p="3px 10px"
                        borderRadius="8px"
                    >
                    {t(`pupilPage.rewardRequestTable.statuses.${status}`)}
                    </Badge>
                </Td>
                <Td>
                    <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                    {createdAt.toLocaleDateString("en-GB")}
                    </Text>
                </Td>
                <Td>
                    <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                    {deliveredAt ? deliveredAt.toLocaleDateString("en-GB") : ""}
                    </Text>
                </Td>
                <Td>
                    <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                    {canceledAt ? canceledAt.toLocaleDateString("en-GB") : ""}
                    </Text>
                </Td>
                <Td>
                    <Flex justifyContent={"space-evenly"}>
                        <Button
                            hidden={shouldHideCompleteButton}
                            _hover={{ backgroundColor: "green", color: "white"}}
                            onClick={() => (onCompleteShopItemOrder(id))}
                        >
                            <CheckIcon />
                        </Button>
                        <Button
                            hidden={shouldHideCancelButton}
                            _hover={{ backgroundColor: "red", color: "white" }}
                            onClick={() => (onCancelShopItemOrder(id))}
                        >
                            <CloseIcon />
                        </Button>
                    </Flex>
                </Td>
            </Tr>
        </>
    );
}
  