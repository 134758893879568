import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box
} from "@chakra-ui/react";
import { ShopItemTable } from "../components/shopItemTable";
import { RewardsStockTableRow } from "../components/rewardsStockTableRow";
import { GetShopItemAssignmentsQuery } from "../models/getShopItemAssignements";

interface ShopItemAssignmentsMap {
    [projectId: string]: {
        title: string,
        shopItemAssignments: any[],
        schools: {
            [schoolId: string]: {
                title: string,
                shopItemAssignments: any[],
                classes: {
                    [classId: string]: {
                        title: string,
                        shopItemAssignments: any[]
                    }
                }
            }
        }
    }
}

export const sortShopItemAssignments = (shopItemAssignments: any[]): any[] => {
    const sortedShopItemAssignments = shopItemAssignments.sort((shopItemAssignment1, shopItemAssignment2) => {
        const { title: title1, shopItem: shopItem1 } = shopItemAssignment1;
        const { title: title2, shopItem: shopItem2 } = shopItemAssignment2;

        const titleAssignment1 = title1 || shopItem1.title;
        const titleAssignment2 = title2 || shopItem2.title;

        const typeAssignment1 = shopItem1.type.type;
        const typeAssignment2 = shopItem2.type.type;

        if (typeAssignment1 < typeAssignment2) {
            return -1;
        }

        if (typeAssignment1 > typeAssignment2) {
            return 1;
        }

        if (titleAssignment1 < titleAssignment2) {
            return -1;
        }
        if (titleAssignment1 > titleAssignment2) {
            return 1;
        }
        return 0;
    });

    return sortedShopItemAssignments;
}

export const mapShopItemAssignments = (getShopItemAssignmentsQueryData: GetShopItemAssignmentsQuery | undefined): ShopItemAssignmentsMap => {
    if (
        !getShopItemAssignmentsQueryData || 
        !getShopItemAssignmentsQueryData.shopItemAssignments ||
        !getShopItemAssignmentsQueryData.shopItemAssignments.length
    ) {
        return {};
    }

    const shopItemAssigments = getShopItemAssignmentsQueryData.shopItemAssignments;
    const data: ShopItemAssignmentsMap = {};
    for (const shopItemAssignment of shopItemAssigments) {
        const { project, school, class: classRoom } = shopItemAssignment;

        if (!project || !project.id) {
            continue;
        }

        if (!data[project.id]) {
            data[project.id] = {
                title: project.title,
                shopItemAssignments: [],
                schools: {}
            };
        }

        if (project && project.id && !school && !classRoom) {
            data[project.id].shopItemAssignments.push(shopItemAssignment);
        }

        if (school && school.id) {
            if (!data[project.id].schools[school.id]) {
                data[project.id].schools[school.id] = {
                    title: school.title,
                    shopItemAssignments: [],
                    classes: {}
                };
            }

            if (school.id && !classRoom) {
                data[project.id].schools[school.id].shopItemAssignments.push(shopItemAssignment);
            }
        }

        if (school && school.id && classRoom && classRoom.id) {
            if (!data[project.id].schools[school.id].classes[classRoom.id]) {
                data[project.id].schools[school.id].classes[classRoom.id] = {
                    title: classRoom.title,
                    shopItemAssignments: []
                };
            }
            data[project.id].schools[school.id].classes[classRoom.id].shopItemAssignments.push(shopItemAssignment);
        }
    }

    return data;
}

export const generateClassAccordionItems = (data: ShopItemAssignmentsMap, projectId: string, schoolId: string) => {
    const classIds = Object.keys(data[projectId].schools[schoolId].classes);
    const sortedClassIds = classIds.sort((classId1, classId2) => {
        const { title: title1 } = data[projectId].schools[schoolId].classes[classId1];
        const { title: title2 } = data[projectId].schools[schoolId].classes[classId2];

        if (title1 < title2) {
            return -1;
        }
        if (title1 > title2) {
            return 1;
        }
        return 0;
    });
    
    return sortedClassIds.map((classId) => {
        const { title, shopItemAssignments } = data[projectId].schools[schoolId].classes[classId];

        return (
            <AccordionItem key={classId}>
                <h2>
                    <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                        {title}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <ShopItemTable hidden={!shopItemAssignments.length}>
                        {sortShopItemAssignments(shopItemAssignments).map((row) => (
                            <RewardsStockTableRow
                                id={row.id}
                                key={row.id}
                                name={row.shopItem.title}
                                type={row.shopItem.type.type}
                                logo={row.image ? row.image.url : row.shopItem.image.url}
                                deliveredRequests={row.deliveredStock}
                                availableStock={row.availableStock}
                                physicalStock={row.physicalStock}
                                openRequestCount={row.openRequestCount}
                            />
                        ))}
                    </ShopItemTable> 
                </AccordionPanel>
            </AccordionItem>
        )
    })
};

export const generateSchoolAccordionItems = (data: ShopItemAssignmentsMap, projectId: string) => {
    const schoolIds = Object.keys(data[projectId].schools);
    const sortedSchoolIds = schoolIds.sort((schoolId1, schoolId2) => {
        const { title: title1 } = data[projectId].schools[schoolId1];
        const { title: title2 } = data[projectId].schools[schoolId2];

        if (title1 < title2) {
            return -1;
        }
        if (title1 > title2) {
            return 1;
        }
        return 0;
    });
    
    return sortedSchoolIds.map((schoolId) => {
        const { title, shopItemAssignments } = data[projectId].schools[schoolId];

        return (
            <AccordionItem key={schoolId}>
                <h2>
                    <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                            {title}
                        </Box>
                    <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <Accordion allowMultiple>
                        <ShopItemTable hidden={!shopItemAssignments.length}>
                            {sortShopItemAssignments(shopItemAssignments).map((row) => (
                                <RewardsStockTableRow
                                    id={row.id}
                                    key={row.id}
                                    name={row.shopItem.title}
                                    type={row.shopItem.type.type}
                                    logo={row.image ? row.image.url : row.shopItem.image.url}
                                    deliveredRequests={row.deliveredStock}
                                    availableStock={row.availableStock}
                                    physicalStock={row.physicalStock}
                                    openRequestCount={row.openRequestCount}
                                />
                            ))}
                        </ShopItemTable> 
                        {
                            generateClassAccordionItems(data, projectId, schoolId)
                        }
                    </Accordion>
                </AccordionPanel>
            </AccordionItem>
        );
    });
};

export const generateProjectAccordionItems = (data: ShopItemAssignmentsMap) => {
    const projectIds = Object.keys(data);
    const sortedProjectIds = projectIds.sort((projectId1, projectId2) => {
        const { title: title1 } = data[projectId1];
        const { title: title2 } = data[projectId2];

        if (title1 < title2) {
            return -1;
        }
        if (title1 > title2) {
            return 1;
        }
        return 0;
    });

    return sortedProjectIds.map((projectId) => {
        const { title, shopItemAssignments } = data[projectId];

        return (
            <AccordionItem key={projectId}>
                <h2>
                <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                    {title}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <Accordion allowMultiple>
                        <ShopItemTable hidden={!shopItemAssignments.length}>
                            {sortShopItemAssignments(shopItemAssignments).map((row) => (
                                <RewardsStockTableRow
                                    id={row.id}
                                    key={row.id}
                                    name={row.shopItem.title}
                                    type={row.shopItem.type.type}
                                    logo={row.image ? row.image.url : row.shopItem.image.url}
                                    deliveredRequests={row.deliveredStock}
                                    availableStock={row.availableStock}
                                    physicalStock={row.physicalStock}
                                    openRequestCount={row.openRequestCount}
                                />
                            ))}
                        </ShopItemTable> 
                        {
                            generateSchoolAccordionItems(data, projectId)
                        }
                    </Accordion>
                </AccordionPanel>
            </AccordionItem>
        );
    });
};