import { Box, Flex, Skeleton, Text, useColorModeValue } from "@chakra-ui/react";

interface Props {
    title: string;
    amount: number | undefined;
    icon: string;
    css?: {
      [key: string]: any;
    };
}

export const Statistic = (props: Props) => {
  const { title, amount, icon, css } = props;

    return (
      <Box
        shadow="base"
        borderWidth="1px"
        borderColor={useColorModeValue("gray.200", "gray.500")}
        backgroundColor={useColorModeValue("gray.50", "gray.700")}
        borderRadius={"xl"}
        padding={"10px"}
        margin={"10px"}
        {...css}
      >
        <Flex direction="column" height={"100%"}>
          <Flex alignItems="center" marginBottom={"10px"}>
            <Text fontSize="xl" fontWeight="500" marginRight={"10px"}>
                {icon}
            </Text>
            <Text fontSize="xl" fontWeight="500">
                {title}
            </Text>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"} height={"100%"}>
            {
              amount === undefined ? (
                <Skeleton height="30px" width="100%" margin="10px"/>
              ) : (
                <Text fontSize="3xl" fontWeight="600">
                {amount.toLocaleString()}
              </Text>
              )
            }
          </Flex>
        </Flex>
      </Box>
    );
};
