import { useEvent } from "event";
import { flatten } from "flat";
import { useTranslation } from "react-i18next";
import { ApplicationRoutes } from "routes";

interface Props {
	routes: ApplicationRoutes;
}

interface Result {
	routePageTitles: ApplicationRoutes;

	setPageTitle: (pageTitle: string) => void;
	trySetRoutePageTitle: (path: string) => void;
    usePageTitleEvents: () => void;
}

export const usePageTitleSetter = (props: Props): Result => {
	const { routes } = props; 
	const { t } = useTranslation();

	const routePageTitles: ApplicationRoutes = {
		index: t("pageTitles.index"),
		tagInput: t("pageTitles.tagInput"),
		pupil: t("pageTitles.pupil"),
		manageStock: t("pageTitles.manageStock")
	};

	const setPageTitle = (pageTitle: string) => {
		document.title = pageTitle;
	};

	const getPathKey = (path: string) => {
		const flattenedRoutes: Record<string, string> = flatten(routes);

		const matchKeys = Object.keys(flattenedRoutes).filter((key) => {
			const value = flattenedRoutes[key];

			if (value === path) {
				return true;
			}

			return false;
		});

		if (matchKeys && matchKeys.length) {
			return matchKeys[0];
		}

		return "";
	};

	const trySetRoutePageTitle = (route: string) => {
		const pathKey = getPathKey(route);

		if (!pathKey) {
			setPageTitle(routePageTitles.index);
			return;
		}

		const title = pathKey
			.split(/[.[\]]/)
			.filter(Boolean)
			.reduce((value, key) => (value as any)?.[key], routePageTitles as any);

		if (title) {
			setPageTitle(title);
			return;
		}

		setPageTitle(routePageTitles.index);
	};

    const usePageTitleEvents = () => {
        useEvent("routeChanged", trySetRoutePageTitle);
    };

	return {
		routePageTitles,

		setPageTitle,
		trySetRoutePageTitle,
        usePageTitleEvents,
	};
};