import { Box, useColorModeValue } from "@chakra-ui/react"

interface Props {
    children: React.ReactNode
}

export const OptionWrapper = (props: Props) => {
    const { children } = props
  
    return (
      <Box
        mb={4}
        shadow="base"
        borderWidth="1px"
        alignSelf={{ base: "center", lg: 'flex-start' }}
        borderColor={useColorModeValue('gray.200', 'gray.500')}
        borderRadius={'xl'}>
        {children}
      </Box>
    );
}