/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteShopItemOrderMutationVariables = Types.Exact<{
  orderId: Types.Scalars['String']['input'];
}>;


export type CompleteShopItemOrderMutation = { __typename?: 'Mutation', completeOneShopItemOrder: { __typename?: 'ShopItemOrder', shopItem?: { __typename?: 'AssignedShopItem', id: string } | null } };


export const CompleteShopItemOrderDocument = gql`
    mutation completeShopItemOrder($orderId: String!) {
  completeOneShopItemOrder(orderId: $orderId) {
    shopItem {
      id
    }
  }
}
    `;
export type CompleteShopItemOrderMutationFn = Apollo.MutationFunction<CompleteShopItemOrderMutation, CompleteShopItemOrderMutationVariables>;

/**
 * __useCompleteShopItemOrderMutation__
 *
 * To run a mutation, you first call `useCompleteShopItemOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteShopItemOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeShopItemOrderMutation, { data, loading, error }] = useCompleteShopItemOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCompleteShopItemOrderMutation(baseOptions?: Apollo.MutationHookOptions<CompleteShopItemOrderMutation, CompleteShopItemOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteShopItemOrderMutation, CompleteShopItemOrderMutationVariables>(CompleteShopItemOrderDocument, options);
      }
export type CompleteShopItemOrderMutationHookResult = ReturnType<typeof useCompleteShopItemOrderMutation>;
export type CompleteShopItemOrderMutationResult = Apollo.MutationResult<CompleteShopItemOrderMutation>;
export type CompleteShopItemOrderMutationOptions = Apollo.BaseMutationOptions<CompleteShopItemOrderMutation, CompleteShopItemOrderMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    