/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShopItemOrdersQueryVariables = Types.Exact<{
  pupilId: Types.Scalars['String']['input'];
}>;


export type GetShopItemOrdersQuery = { __typename?: 'Query', shopItemOrders: Array<{ __typename?: 'ShopItemOrder', id: string, deliveredAt?: any | null, canceledAt?: any | null, createdAt: any, shopItem?: { __typename?: 'AssignedShopItem', title?: string | null, image: { __typename?: 'Asset', url: string }, type: { __typename?: 'ShopItemType', type: Types.ShopItemTypeType } } | null }> };


export const GetShopItemOrdersDocument = gql`
    query getShopItemOrders($pupilId: String!) {
  shopItemOrders(
    filter: {pupil: {id: {equals: $pupilId}}}
    sort: [{field: "createdAt", order: DESC}]
  ) {
    id
    shopItem {
      title
      image {
        url
      }
      type {
        type
      }
    }
    deliveredAt
    canceledAt
    createdAt
  }
}
    `;

/**
 * __useGetShopItemOrdersQuery__
 *
 * To run a query within a React component, call `useGetShopItemOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItemOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItemOrdersQuery({
 *   variables: {
 *      pupilId: // value for 'pupilId'
 *   },
 * });
 */
export function useGetShopItemOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetShopItemOrdersQuery, GetShopItemOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShopItemOrdersQuery, GetShopItemOrdersQueryVariables>(GetShopItemOrdersDocument, options);
      }
export function useGetShopItemOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopItemOrdersQuery, GetShopItemOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShopItemOrdersQuery, GetShopItemOrdersQueryVariables>(GetShopItemOrdersDocument, options);
        }
export type GetShopItemOrdersQueryHookResult = ReturnType<typeof useGetShopItemOrdersQuery>;
export type GetShopItemOrdersLazyQueryHookResult = ReturnType<typeof useGetShopItemOrdersLazyQuery>;
export type GetShopItemOrdersQueryResult = Apollo.QueryResult<GetShopItemOrdersQuery, GetShopItemOrdersQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    