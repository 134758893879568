import { Background } from "components/background/background";
import { Layout } from "components/layout/layout";
import { event } from "event";
import { ManageStockPage } from "pages/manageStock/manageStockPage";
import { PupilPage } from "pages/pupil/pupilPage";
import { TagInputPage } from "pages/tagInput/tagInputPage";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useRouteDefinitionGenerator } from "useRouteDefinitionGenerator";
import { usePageTitleSetter } from "utils/pageTitleSetter";
import { HomePage } from "./pages/home/homePage";

export interface ApplicationRoutes {
    index: string;
    tagInput: string;
    pupil: string;
    manageStock: string;
};

export const Content = () => {
    const { getRoutes } = useRouteDefinitionGenerator();
    const routeDefinitions = getRoutes();

    const { usePageTitleEvents } = usePageTitleSetter({ routes: routeDefinitions });
    usePageTitleEvents();

    const location = useLocation();
    useEffect(() => {
        if (location) {
            event.emit("routeChanged", location.pathname);

        }
    }, [location]);

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="*" element={<Navigate to={routeDefinitions.index} />} />

                <Route path="/" element={<HomePage />} />
                <Route element={<Background />}>
                    <Route path={routeDefinitions.tagInput} element={<TagInputPage />} />
                    <Route path={routeDefinitions.pupil} element={<PupilPage />} />
                    <Route path={routeDefinitions.manageStock} element={<ManageStockPage />} />
                </Route>
            </Route>
        </Routes>
    );
};
