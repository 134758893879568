import {
    Table,
    Tbody,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    hidden: boolean;
    children: ReactNode;
};

export const ShopItemTable = (props: Props) => {
    const { t } = useTranslation();
    const { children, hidden } = props;

    return (
        <Table variant="simple" color="gray.700" hidden={hidden}>
            <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                {[
                    t("manageStockPage.shopItemTable.shopItem"),
                    t("manageStockPage.shopItemTable.physicalStock"),
                    t("manageStockPage.shopItemTable.openRequests"),
                    t("manageStockPage.shopItemTable.amountDelivered"), 
                    ""
                ].map((caption, idx) => {
                    return (
                        <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                            {caption}
                        </Th>
                    );
                })}
                </Tr>
            </Thead>
            <Tbody>
                {children}
            </Tbody>
        </Table>
    );
};